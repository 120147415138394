<template>
  <div
    style="min-width: 100%"
    class="mass-edit-shortlists-container"
    v-if="init"
  >
    <div class="mass-edit-shortlists-top">
      <div class="shortlists-box company">
        <div class="selected">
          <h4>Company shortlists</h4>
          <div class="info" v-if="selectedProduct === null">
            Select a product
          </div>
          <div class="info" v-else-if="selectedCompanyShortlists.length === 0">
            No shortlists with selected product
          </div>
          <div
            class="item"
            v-for="list in selectedCompanyShortlists"
            :key="list.id"
          >
            <span class="name">{{ list.name }}</span>
            <span class="company">{{
              getCompanyNameByVismaId(list.companyId)
            }}</span>
            <span
              class="exclude"
              @click="
                $store.dispatch('massEditShortlists/updateExclusion', {
                  field: 'Company',
                  type: 'add',
                  value: list.id,
                })
              "
              >Exclude</span
            >
          </div>
        </div>
        <div class="excluded" v-if="excludedCompanyShortlists.length > 0">
          <h4>Excluded</h4>
          <div
            class="item"
            v-for="listId in excludedCompanyShortlists"
            :key="'excluded-' + listId"
          >
            <span class="name">{{ getCompanyShortlistNameById(listId) }}</span>
            <span class="company">{{
              getCompanyNameByVismaId(getCompanyShortlistCompanyIdById(listId))
            }}</span>
            <span
              class="exclude"
              @click="
                $store.dispatch('massEditShortlists/updateExclusion', {
                  field: 'Company',
                  type: 'remove',
                  value: listId,
                })
              "
              >Remove</span
            >
          </div>
        </div>
      </div>

      <div class="shortlists-box shared">
        <div class="selected">
          <h4>Shared shortlists</h4>
          <div class="info" v-if="selectedProduct === null">
            Select a product
          </div>
          <div class="info" v-else-if="selectedSharedShortlists.length === 0">
            No shortlists with selected product
          </div>
          <div
            class="item"
            v-for="list in selectedSharedShortlists"
            :key="list.id"
          >
            <span class="name">{{ list.nameInternal }}</span>
            <span
              class="exclude"
              @click="
                $store.dispatch('massEditShortlists/updateExclusion', {
                  field: 'Shared',
                  type: 'add',
                  value: list.id,
                })
              "
              >Exclude</span
            >
          </div>
        </div>
        <div class="excluded" v-if="excludedSharedShortlists.length > 0">
          <h4>Excluded</h4>
          <div
            class="item"
            v-for="listId in excludedSharedShortlists"
            :key="'excluded-' + listId"
          >
            <span class="name">{{
              getSharedShortlistNameInternalById(listId)
            }}</span>
            <span
              class="exclude"
              @click="
                $store.dispatch('massEditShortlists/updateExclusion', {
                  field: 'Shared',
                  type: 'remove',
                  value: listId,
                })
              "
              >Remove</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="mass-edit-shortlists-bottom">
      <div class="step-1">
        <h2>1. Select product</h2>
        <div class="shortlists-products-search">
          <input
            type="search"
            placeholder="Search product..."
            v-model="search"
          />
          <div class="shortlist-search-container">
            <div
              v-for="product in getProductsBySearch(search)"
              :key="product.firebaseId"
            >
              <span class="id">{{ product.vismaId }}</span>
              <span class="name">{{ product.name }}</span>
              <span
                class="action remove"
                v-if="selectedProduct == product.firebaseId"
                @click="
                  $store.commit('massEditShortlists/setSelectedProduct', null)
                "
                >Unselect</span
              >
              <span
                class="action add"
                v-else
                @click="
                  $store.commit(
                    'massEditShortlists/setSelectedProduct',
                    product.firebaseId
                  )
                "
                >Select</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="step-2">
        <h2 v-if="replace">2. Select replacement product</h2>
        <h2 v-else>2. Select action</h2>
        <div
          class="step-2-buttons"
          v-if="selectedProduct !== null && !replace && replaceProduct === null"
        >
          <p>
            Selected product:
            <span>{{ getProductByFirebaseId(selectedProduct).name }}</span>
          </p>
          <button
            class="clear"
            @click="$store.dispatch('massEditShortlists/exitEditing')"
          >
            Clear selection
          </button>
          <button @click="$store.commit('massEditShortlists/setReplace', true)">
            Replace product
          </button>
          <button class="remove" @click="initDelete">Delete product</button>
        </div>
        <div v-if="replace" class="shortlists-products-search">
          <div class="replace-input-button">
            <input
              type="search"
              placeholder="Search product..."
              v-model="searchReplace"
            />
            <button
              @click="$store.commit('massEditShortlists/setReplace', false)"
            >
              Go back
            </button>
          </div>
          <div class="shortlist-search-container">
            <div
              v-for="product in getProductsBySearch(searchReplace)"
              :key="'replace-' + product.firebaseId"
            >
              <span class="id">{{ product.vismaId }}</span>
              <span class="name">{{ product.name }}</span>
              <span
                class="action add"
                @click="selectReplacement(product.firebaseId)"
                >Select</span
              >
            </div>
          </div>
        </div>
        <div v-if="!replace && replaceProduct" class="replace-confirm">
          <p>
            Replace product
            <span>{{ getProductByFirebaseId(selectedProduct).name }}</span> with
            <span>{{ getProductByFirebaseId(replaceProduct).name }}</span> on
            shortlists selected above?
          </p>
          <div class="buttons">
            <button
              class="cancel"
              @click="
                $store.commit('massEditShortlists/setReplaceProduct', null)
              "
            >
              Cancel
            </button>
            <button @click="doReplacement">Confirm</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "MassEditShortlists",
  data() {
    return {
      search: "",
      searchReplace: "",
    };
  },
  computed: {
    ...mapState({
      selectedProduct: (state) => state.massEditShortlists.selectedProduct,
      excludedCompanyShortlists: (state) =>
        state.massEditShortlists.excludedCompanyShortlists,
      excludedSharedShortlists: (state) =>
        state.massEditShortlists.excludedSharedShortlists,
      loading: (state) => state.massEditShortlists.loading,
      replace: (state) => state.massEditShortlists.replace,
      replaceProduct: (state) => state.massEditShortlists.replaceProduct,
      init: (state) => state.massEditShortlists.init,
    }),
    ...mapGetters("products", [
      "getProductByFirebaseId",
      "getProductsBySearch",
    ]),
    ...mapGetters("settings", ["getCompanyNameByVismaId"]),
    ...mapGetters("massEditShortlists", [
      "selectedCompanyShortlists",
      "selectedSharedShortlists",
      "getCompanyShortlistNameById",
      "getCompanyShortlistCompanyIdById",
      "getSharedShortlistNameInternalById",
    ]),
  },
  components: {},
  methods: {
    doReplacement: function () {
      if (this.loading) {
        return;
      }

      this.$store.commit("massEditShortlists/setLoading", true);
      this.$store.dispatch("massEditShortlists/replaceProduct").then((res) => {
        this.$toast.center(
          "Successfully replaced <span>" +
            this.getProductByFirebaseId(res.productRemoved).name +
            "</span> with <span>" +
            this.getProductByFirebaseId(res.productAdded).name +
            "</span> in <span>" +
            res.amount +
            "</span> shortlists"
        );
        this.$store.commit("massEditShortlists/setLoading", false);
      });
    },
    selectReplacement: function (productId) {
      this.$store.commit("massEditShortlists/setReplaceProduct", productId);
      this.$store.commit("massEditShortlists/setReplace", false);
    },
    initDelete: function () {
      if (this.loading) {
        return;
      }

      let confirm = window.confirm(
        "About to remove product from selected shortlists.\nThis action can not be reversed."
      );

      if (confirm) {
        this.$store.commit("massEditShortlists/setLoading", true);
        this.$store.dispatch("massEditShortlists/deleteProduct").then((res) => {
          this.$toast.center(
            "Successfully deleted <span>" +
              this.getProductByFirebaseId(res.product).name +
              "</span> from <span>" +
              res.amount +
              "</span> shortlists"
          );
          this.$store.commit("massEditShortlists/setLoading", false);
        });
      } else {
        return;
      }
    },
  },
  async created() {
    if (!this.init) {
      await this.$store.dispatch("companyShortlists/bindShortlists");
      await this.$store.dispatch("sharedShortlists/bindShortlists");
      this.$store.commit("massEditShortlists/setInit", true);
    }
  },
  async beforeDestroy() {
    this.$store.commit("massEditShortlists/setInit", false);
    await this.$store.dispatch("companyShortlists/unbindShortlists");
    await this.$store.dispatch("sharedShortlists/unbindShortlists");
  },
};
</script>
